import React from 'react'
import { CiLocationArrow1, CiPhone } from "react-icons/ci";
import { SlSocialInstagram, SlSocialLinkedin, SlSocialTwitter, SlSocialFacebook, SlSocialYoutube } from "react-icons/sl";
import { RiTwitterXLine } from "react-icons/ri";
import { CiMail } from "react-icons/ci";
import { PiCopyright } from "react-icons/pi";

import { Link } from 'react-router-dom';
const Footer = () => {

  return (
    <div className='w-[1200px] md:w-full'>

      <div className="md:flex justify-around h-[2800px] md:h-full  bg-blue-950 text-white p-5 font-[content] text-sm py-10">
        <div className='mt-16 mb-32 md:my-0 '>
          <h1 className="text-[#f42f54] font-serif w-fit text-7xl md:text-2xl mb-16 md:mb-5">REACH US</h1>
          <div className='px-16 md:px-0 md:mpx-0'>
            <div className="flex mb-10 md:mb-2">
              <div className="mr-10 md:mr-2">
                <CiLocationArrow1 className='text-9xl md:text-sm' />
              </div>
              <div className='my-auto'>
                <a className="hover:text-green-600 text-5xl md:text-sm  duration-500" href='https://maps.app.goo.gl/oLSpZ53cV2fPZFqU8' target='_blank'>BharathiDhasanar MHSS</a>
              </div>
            </div>
            <div className="flex mb-10 md:mb-2">
              <div className="mr-10 md:mr-2">
                <CiPhone className='text-9xl md:text-sm' />
              </div>
              <div className='my-auto'>
                <a className="hover:text-green-600 text-5xl md:text-sm  duration-500" href='tel:9042316003' target='_blank'> 9042316003/9042316004</a>
              </div>
            </div>
            <div className="flex mb-10 md:mb-2">
              <div className="mr-10 md:mr-2">
                <CiMail className='text-9xl md:text-sm' />
              </div>
              <div className='my-auto'>
                <a className="hover:text-green-600 text-5xl md:text-sm  duration-500" href='mailto:principalakm@bmhsschool.in' target='_blank'>principalakm@bmhsschool.in</a>
              </div>
            </div>
          </div>
        </div>
        <div className='px-16 md:px-0'>
          <div className="text-[#f42f54] font-serif text-7xl md:text-2xl mb-16 md:mb-5"> BGS SCHOOLS</div>
          <div className="grid grid-cols-2 text-5xl md:text-sm">
            <div className="grid">
              <a className="mb-16 md:mb-2 cursor-pointer hover:text-green-600 duration-500" target='_blank' href='https://bgsarakkonam.com'>BGS - Arakkonam</a>
              <a className="mb-16 md:mb-2 cursor-pointer hover:text-green-600 duration-500" target='_blank' href='https://bgsthimiri.com'>BGS - Thimiri</a>
              <a className="mb-16 md:mb-2 cursor-pointer hover:text-green-600 duration-500" target='_blank' href='https://bgscheyyar.com'>BGS - Cheyyar</a>
              <a className="mb-16 md:mb-2 cursor-pointer hover:text-green-600 duration-500" target='_blank' href='https://bgswalajapet.com'>BGS - Walajahpet</a>
              <a className="mb-16 md:mb-2 cursor-pointer hover:text-green-600 duration-500" target='_blank' href='https://bgskaveripakkam.com'>BGS - Kaveripakkam</a>
              <a className="mb-16 md:mb-2 cursor-pointer hover:text-green-600 duration-500" target='_blank' href='https://bgspanapakkam.com'>BGS - Panapakkam</a>
            </div>
            {/* <div className="grid">
          
        <Link className="mb-16 md:mb-2 hover:text-green-600 duration-500" to=''>Co-Curriculum</Link>
        <Link className="mb-16 md:mb-2 hover:text-green-600 duration-500" to=''>Extra-Curriculum</Link>
        <Link className="mb-16 md:mb-2 hover:text-green-600 duration-500" to=''>Teaching Methodology</Link>
        <Link className="mb-16 md:mb-2 hover:text-green-600 duration-500" to=''>Club Activities</Link>
        <Link className="mb-16 md:mb-2 hover:text-green-600 duration-500" to=''>Awards and Achievements</Link>
        <Link className="mb-16 md:mb-2 hover:text-green-600 duration-500" to=''>Gallery</Link>
        </div> */}
          </div>
        </div>
        <div className='my-16 md:my-0'>
          <h1 className="text-[#f42f54] md:-ml-2 ml-10 font-serif text-7xl md:text-2xl w-64 mb-16 md:mb-5">SCHOOL WORKING HOURS</h1>
          <div className='px-16 md:px-0'>


            <div className="mb-16 text-5xl md:text-sm md:mb-2 ">
              Monday to Friday: 8AM-6PM
            </div>
            <div className="mb-16 text-5xl md:text-sm md:mb-2 ">

              Saturday: 9AM-4PM
            </div>
            <div className="mb-16 text-5xl md:text-sm md:mb-2 ">

              Sunday: Closed
            </div>
          </div>

          <div className='my-16 md:my-0'>
            <h1 className="text-[#f42f54] md:-ml-1  font-serif text-7xl w-fit md:w-36 md:text-2xl my-5 mb-16 md:mb-0">FOLLOW US </h1>
            <div className="flex px-16 md:pt-5 md:px-0">
              <div className="mr-16 md:mr-5 ">
                <a href='https://www.instagram.com/bharathidhasanar_schools?igsh=b2ppNTEwcDFpd28y' target='_blank' >
                  <SlSocialInstagram className="text-7xl md:text-2xl hover:text-green-600 duration-500" />
                </a>
              </div>
              <div className="mr-16 md:mr-5">
                <a href='https://www.facebook.com/BMHSSAKM' target='_blank' >
                  <SlSocialFacebook className="text-7xl md:text-2xl hover:text-green-600 duration-500" />
                </a>
              </div>
  
              <div className="mr-16 md:mr-5">
                <a href='https://x.com/bgsinstitutions?t=fcHBZt_x-lmYUWPC_MXzDg&s=08 ' target='_blank'>
                  <RiTwitterXLine className=" text-7xl md:text-2xl hover:text-green-600 duration-500" />
                </a>
              </div>
              <div className="mr-16 md:mr-5">
                <a href='https://youtube.com/@bharathidhasanarmatrichrse5639?si=bDPWCRf2uHMarzgl' target='_blank'>
                  <SlSocialYoutube className=" text-7xl md:text-2xl hover:text-green-600 duration-500" />
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="bg-[rgb(16,23,45)]   text-white font-serif text-sm p-1 px-32 py-10 md:py-0 md:flex justify-around">
        <div className="text-5xl md:text-sm leading-normal my-auto">

          Copyrights © 2024 - All Rights Reserved by BharathiDhasanar Group of Schools.

        </div>
        <div>
          <div className='text-5xl flex md:text-sm  md:my-0'>
            <div className='my-auto'>

              Designed and Developed By :
            </div>
            <div className='flex md:ml-5 my-5 md:my-0 mx-auto cursor-pointer'>
              <a href='https://api.whatsapp.com/send?phone=7010354265' className='flex'>

                <span className='text-8xl md:text-3xl'>𝒁</span>
                <p className='my-auto'>atn.</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer