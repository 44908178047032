import React, { useState, useEffect, useRef } from 'react';
import './Carousel.css'; // Assuming the CSS is saved in Carousel.css
import header2 from '../image/header2.jpg'
import header3 from '../image/header3.jpg';
import kaveripakkam from '../image/kaveripakkam.jpg';
import walajah from '../image/walajah.jpg';
import cheyyar from '../image/cheyyar.jpg';
import thimiri from '../image/thimiri.jpg'; 
import panam from '../assets/images/panam.jpeg'
const Carousel = () => {
    const sliderItemsInitial = [
        { id: 1, author: 'Arakkonam', title: 'BHARATHIDHASANAR', topic: 'MATRIC SCHOOL', image: header2, description: 'Lorem ipsum dolor...',link:'https://bgsarakkonam.com' },
        { id: 2, author: 'Panapakkam', title: 'BHARATHIDHASANAR', topic: 'PUBLIC SCHOOL', image: panam, description: 'Lorem ipsum dolor...',link:'https://bgspanapakkam.com' },
        { id: 3, author: 'Kaveripakkam', title: 'BHARATHIDHASANAR', topic: 'MATRIC SCHOOL', image: kaveripakkam, description: 'Lorem ipsum dolor...',link:'https://bgskaveripakkam.com' },
        { id: 4, author: 'Walajahpet', title: 'BHARATHIDHASANAR', topic: 'MATRIC SCHOOL', image: walajah, description: 'Lorem ipsum dolor...',link:'https://bgswalajapet.com' },
        { id: 5, author: 'Cheyyar', title: 'BHARATHIDHASANAR', topic: 'MATRIC SCHOOL', image: cheyyar, description: 'Lorem ipsum dolor...',link:'https://bgscheyyar.com' },
        { id: 6, author: 'Thimiri', title: 'BHARATHIDHASANAR', topic: 'PUBLIC SCHOOL', image: thimiri, description: 'Lorem ipsum dolor...',link:'https://bgsthimiri.com' }
    ];

    const [sliderItems, setSliderItems] = useState(sliderItemsInitial);
    const [thumbnailItems, setThumbnailItems] = useState(sliderItemsInitial);
    const [isNext, setIsNext] = useState(false);
    const [isPrev, setIsPrev] = useState(false);
    const [key, setKey] = useState(0); // Adding key state to force re-render

    const nextRef = useRef(null);
    const prevRef = useRef(null);
    const carouselRef = useRef(null);
    const sliderRef = useRef(null);
    const thumbnailRef = useRef(null);
    const timeRef = useRef(null);

    const timeRunning = 1000;
    const timeAutoNext = 7000;
    const timeoutRef = useRef(null);
    const autoNextRef = useRef(null);

    const showSlider = (type) => {
        // Clone the current slider and thumbnail items
        const newSliderItems = [...sliderItems];
        const newThumbnailItems = [...thumbnailItems];

        if (type === 'next') {
            // Shift the first item to the end of the array
            const firstSliderItem = newSliderItems.shift();
            const firstThumbnailItem = newThumbnailItems.shift();
            newSliderItems.push(firstSliderItem);
            newThumbnailItems.push(firstThumbnailItem);
            
            // Update state for next
            setIsNext(true);
            setIsPrev(false);
        } else {
            // Pop the last item to the beginning of the array
            const lastSliderItem = newSliderItems.pop();
            const lastThumbnailItem = newThumbnailItems.pop();
            newSliderItems.unshift(lastSliderItem);
            newThumbnailItems.unshift(lastThumbnailItem);
            
            // Update state for prev
            setIsNext(false);
            setIsPrev(true);
        }

        // Update state with new items
        setSliderItems(newSliderItems);
        setThumbnailItems(newThumbnailItems);
        
        // Force re-render by updating the key
        setKey(prevKey => prevKey + 1); 

        // Clear existing timeout for animation
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            setIsNext(false);
            setIsPrev(false);
        }, timeRunning);

        // Clear existing timeout for auto next
        clearTimeout(autoNextRef.current);
        autoNextRef.current = setTimeout(() => {
            showSlider('next');
        }, timeAutoNext);
    };

    useEffect(() => {
        autoNextRef.current = setTimeout(() => {
            showSlider('next');
        }, timeAutoNext);

        return () => {
            clearTimeout(autoNextRef.current);
        };
    }, [sliderItems]);

    return (
        <div className={`carousel ${isNext ? 'next' : ''} ${isPrev ? 'prev' : ''} w-[1200px] md:w-full h-[2600px] md:h-screen`} ref={carouselRef}>
             
            <div className="list" ref={sliderRef} key={key}>
                {sliderItems.map((item, index) => (
                    <div className="item " key={index} >
                        
                        {/* <div className="gradient"></div> */}
                        <img src={item.image}  className='' alt={item.title} />
                        
                        <div className="content my-auto ">
                            <div className="author text-9xl  md:text-5xl">{item.author}</div>
                            <div className="title text-[120px] md:text-7xl">{item.title}</div>
                            <div className="topic text-7xl md:text-5xl">{item.topic}</div>
                            {/* <div className="des">{item.description}</div> */}
                            <div className="buttons text-3xl md:text-sm  text-black">
                                <a target='_blank' href={item.link}> <button className='py-3 px-2 rounded-lg '>SEE MORE</button></a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="thumbnail" ref={thumbnailRef}>
                {thumbnailItems.map((item, index) => (
                    <div className="item h-[400px] w-[500px] md:w-[200px] md:h-[200px]" key={index}>
                        <img src={item.image} alt={item.title} />
                        <div className="content">
                            {/* <div className="title">{item.title}</div> */}
                            <div className="text-[#ffbf00] text-shadow-black text-center text-5xl md:text-sm">{item.author}</div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="arrows">
                <button id="prev" ref={prevRef} onClick={() => showSlider('prev')}>&lt;</button>
                <button id="next" ref={nextRef} onClick={() => showSlider('next')}>&gt;</button>
            </div>

            <div className="time" ref={timeRef}></div>
        </div>
    );
};

export default Carousel;